/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Plugin({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M14.4 9.6c-2.347 0-4.267 1.173-4.267 2.667s1.92 2.667 4.267 2.667c2.347 0 4.267-1.173 4.267-2.667s-1.92-2.667-4.267-2.667zM14.4 13.867c-1.813 0-3.2-0.853-3.2-1.6s1.387-1.6 3.2-1.6 3.2 0.853 3.2 1.6-1.387 1.6-3.2 1.6z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />
      <path d="M26.827 10.133c0.267-0.32 0.373-0.693 0.373-1.12 0-1.493-1.92-2.667-4.267-2.667-1.227 0-2.293 0.32-3.040 0.8l-1.387-0.587c0.107-0.213 0.16-0.48 0.16-0.747 0-1.493-1.92-2.667-4.267-2.667s-4.267 1.173-4.267 2.667c0 0.267 0.053 0.48 0.16 0.747l-1.067 0.48c-0.747-0.427-1.76-0.693-2.827-0.693-2.347 0-4.267 1.173-4.267 2.667 0 0.32 0.107 0.64 0.267 0.96l-2.4 1.067v10.56l14.4 6.133 14.4-6.187v-10.507l-1.973-0.907zM14.4 16.747l-12.533-5.387 1.333-0.533c0.8 0.533 1.92 0.907 3.2 0.907 2.347 0 4.267-1.173 4.267-2.667 0-0.427-0.16-0.853-0.48-1.227l0.8-0.32c0.8 0.64 2.027 1.067 3.413 1.067s2.613-0.427 3.413-1.067l1.227 0.533c-0.213 0.32-0.373 0.693-0.373 1.067 0 1.493 1.92 2.667 4.267 2.667 1.173 0 2.24-0.267 2.987-0.747l1.013 0.427-12.533 5.28zM26.133 9.013c0 0.747-1.387 1.6-3.2 1.6s-3.2-0.853-3.2-1.6 1.387-1.6 3.2-1.6c1.813 0 3.2 0.853 3.2 1.6zM14.4 4.267c1.813 0 3.2 0.853 3.2 1.6s-1.387 1.6-3.2 1.6-3.2-0.853-3.2-1.6 1.387-1.6 3.2-1.6zM6.4 7.413c1.813 0 3.2 0.853 3.2 1.6s-1.387 1.6-3.2 1.6c-1.813 0-3.2-0.853-3.2-1.6s1.387-1.6 3.2-1.6zM1.067 12.16l12.8 5.493v8.693l-12.8-5.493v-8.693zM14.933 26.347v-8.693l12.8-5.493v8.693l-12.8 5.493z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Plugin;
