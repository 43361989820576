/* eslint-disable import/no-cycle */
import { GrantMapMutable, UserGrant, UserGrantMutable } from '../controllers/auth/types';

export const booleanDisplayFilter = ['Yes', 'No'];

export const errorMessages = {
  fileTooLarge: 'The files you have selected are too large. Please make sure the sum of all file sizes is 20 megabytes or less.',
};

export const oneMillion = 1000000;

export const monitoredIssueDetailTypes = [
  'feature_outage',
  'retailer_outage',
  'product_notification',
  'collection_outage',
];

export const tokenAttemptUri = '/tokenAttempt';

export const grantTranslationDict: Readonly<{ [grant in UserGrant]: string }> = {
  AMAZON_AD_VIEWER: 'Amazon Advertising',
  AMAZON_POS_VIEWER: 'Amazon POS Viewer',
  AVAILABILITY_VIEWER: 'Availability Viewer',
  BEST_BUY_AD_VIEWER: 'Best Buy Advertising Viewer',
  CONTENT_VIEWER: 'Content Viewer',
  HOME_DEPOT_AD_VIEWER: 'Home Depot Advertising Viewer',
  HOME_DEPOT_POS_VIEWER: 'Home Depot POS Viewer',
  LOWES_AD_VIEWER: 'Lowes Advertising Viewer',
  LOWES_POS_VIEWER: 'Lowes POS Viewer',
  MACYS_AD_VIEWER: 'Macy\'s Advertising Viewer',
  MARKETPLACE_VIEWER: 'Marketplace Viewer',
  MARSHALL_OMNICHANNEL_AD_VIEWER: 'Omnichannel Advertising Viewer',
  MARSHALL_OMNICHANNEL_PROMO_VIEWER: 'Omnichannel Promotions Viewer',
  MEIJER_POS_VIEWER: 'Meijer POS Viewer',
  ORG_ADMIN: 'Organization Administrator',
  ORG_OWNER: 'Organization Owner',
  PRICING_VIEWER: 'Pricing Viewer',
  USER_ADMIN: 'User Administration',
  PRODUCT_ADMIN: 'Product Administrator',
  REPORT_ADMIN: 'Report Administrator',
  REVIEW_VIEWER: 'Review Viewer',
  SOV_ADMIN: 'Share of Voice Administrator',
  SOV_VIEWER: 'Share of Voice Viewer',
  TARGET_AD_VIEWER: 'Target Advertising Viewer',
  TARGET_POS_VIEWER: 'Target POS Viewer',
  WALMART_POS_VIEWER: 'Walmart POS Viewer',
  WAYFAIR_AD_VIEWER: 'Wayfair Advertising',
  WAYFAIR_POS_VIEWER: 'Wayfair POS Viewer',
  VIEWER: 'Can view all dashboards',
  MEMBER: '', // dont display MEMBER,
  ADMIN: '', // dont display ADMIN,
  APPLICATION_ANNOUNCER: '',
  APPLICATION_NOTIFIER: '',
  APPLICATION_RESOURCE_MANAGER: '',
};

export const NO_PERM_GRANT_MAP: Readonly<GrantMapMutable> = {
  AMAZON_AD_VIEWER: false,
  AMAZON_POS_VIEWER: false,
  AVAILABILITY_VIEWER: false,
  CONTENT_VIEWER: false,
  HOME_DEPOT_AD_VIEWER: false,
  HOME_DEPOT_POS_VIEWER: false,
  LOWES_POS_VIEWER: false,
  MARKETPLACE_VIEWER: false,
  MARSHALL_OMNICHANNEL_AD_VIEWER: false,
  MARSHALL_OMNICHANNEL_PROMO_VIEWER: false,
  MEIJER_POS_VIEWER: false,
  PRICING_VIEWER: false,
  PRODUCT_ADMIN: false,
  REPORT_ADMIN: false,
  REVIEW_VIEWER: false,
  SOV_ADMIN: false,
  SOV_VIEWER: false,
  TARGET_POS_VIEWER: false,
  ORG_ADMIN: false,
  ORG_OWNER: false,
  USER_ADMIN: false,
  VIEWER: false,
  WALMART_POS_VIEWER: false,
  WAYFAIR_AD_VIEWER: false,
  WAYFAIR_POS_VIEWER: false,
  APPLICATION_RESOURCE_MANAGER: false,
  APPLICATION_NOTIFIER: false,
  APPLICATION_ANNOUNCER: false,
  BEST_BUY_AD_VIEWER: false,
  LOWES_AD_VIEWER: false,
  MACYS_AD_VIEWER: false,
  TARGET_AD_VIEWER: false,
};

export const VIEWER_GRANTS: ReadonlyArray<UserGrantMutable> = [
  'AMAZON_AD_VIEWER',
  'AMAZON_POS_VIEWER',
  'AVAILABILITY_VIEWER',
  'CONTENT_VIEWER',
  'HOME_DEPOT_AD_VIEWER',
  'HOME_DEPOT_POS_VIEWER',
  'LOWES_POS_VIEWER',
  'MARKETPLACE_VIEWER',
  'MARSHALL_OMNICHANNEL_AD_VIEWER',
  'MARSHALL_OMNICHANNEL_PROMO_VIEWER',
  'MEIJER_POS_VIEWER',
  'PRICING_VIEWER',
  'REPORT_ADMIN',
  'REVIEW_VIEWER',
  'SOV_VIEWER',
  'TARGET_POS_VIEWER',
  'WALMART_POS_VIEWER',
  'WAYFAIR_AD_VIEWER',
  'WAYFAIR_POS_VIEWER',
];

export const publicPageList = [
  '/home',
  '/cookie-policy',
  '/features',
  '/help',
  '/privacy-policy',
  '/terms-of-service',
  '/training',
];

export const regexTestForUuid = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';

export const oAuthLocalStoreKeyPrefix = 'sp-api-request-state';
