/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Refresh({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M27.729 18.664c0 6.467-5.261 11.729-11.729 11.729s-11.729-5.261-11.729-11.729c0-6.452 5.237-11.703 11.684-11.728v5.333l10.129-5.865-10.129-5.864v5.33c-7.047 0.024-12.751 5.741-12.751 12.794 0 7.065 5.727 12.795 12.795 12.795 7.066 0 12.795-5.73 12.795-12.795h-1.066zM17.022 2.39l6.935 4.015-6.935 4.016v-8.030z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Refresh;
