/* istanbul ignore file */

import React, { SVGProps } from 'react';

function Unlock({
  fill = '#FFFFFF',
  width = '27',
  height = '27',
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
      viewBox="0 0 32 32"
    >

      <path d="M23.464 15.467h-13.328v-6.397c0-3.234 2.631-5.864 5.865-5.864 3.233 0 5.863 2.631 5.863 5.864v2.133h1.066v-2.133c0-3.827-3.103-6.931-6.93-6.931-3.828 0-6.932 3.103-6.932 6.931v6.398h-3.732v14.394h21.325v-14.394h-3.199zM25.596 28.795h-19.192v-12.262h19.192v12.262z" fill={fill} stroke={props.stroke} strokeWidth={props.strokeWidth} />

    </svg>
  );
}
export default Unlock;
